/* ----

# Pio Plugin
# By: Dreamer-Paul
# Modify: journey-ad
# Last Update: 2021.5.10

一个支持更换 Live2D 模型的 Typecho 插件。

本代码为奇趣保罗原创，并遵守 GPL 2.0 开源协议。欢迎访问我的博客：https://paugram.com

---- */

.pio-container {
    bottom: 0;
    z-index: 52;
    color: #666;
    position: fixed;
    user-select: none;
}

.pio-container .pio-show {
    left: -1.2em;
    bottom: -0.2em;
    width: 6em;
    height: 6.8em;
    display: none;
    cursor: pointer;
    position: absolute;
    transition: all .3s;
    background: url(../img/avatar.png) center/contain;
    opacity: 0.7;
}

.pio-container.hidden .pio-show {
    display: block;
}

.pio-container.hidden .pio-show:hover {
    opacity: 0.9;
    transform: translateX(.5em);
}

.pio-container.hidden #pio {
    display: none;
}

.pio-container.hidden .pio-dialog {
    display: none;
}

.pio-container.left {
    left: 0
}

.pio-container.right {
    right: 0
}

.pio-container.active {
    cursor: move
}

.pio-container.static {
    pointer-events: none
}

.pio-container .pio-action {
    top: 3em;
    opacity: 0;
    position: absolute;
    transition: opacity .3s;
}

.pio-container.left .pio-action {
    right: 0
}

.pio-container.right .pio-action {
    left: 0
}

.pio-container:hover .pio-action {
    opacity: 1
}

.pio-action span {
    color: #fff;
    width: 2em;
    height: 2em;
    margin-bottom: 0.6em;
    display: block;
    cursor: pointer;
    text-align: center;
    margin-bottom: .5em;
    background-size: 100%;
}

.pio-action .pio-home {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M472.86 249.918V361.57c-.024 61.53-49.899 111.404-111.431 111.43h-222.86c-61.529-.026-111.403-49.9-111.428-111.43V249.918a111.828 111.828 0 0 1 35.658-81.79L189.383 50.904c34.128-31.872 87.109-31.872 121.234 0l126.585 117.224a111.825 111.825 0 0 1 35.658 81.79z' fill='%23c3cbd3'/%3E%3Cpath d='M327.334 317.02c-.062 9.206-7.511 16.653-16.717 16.715H189.383c-12.867 0-20.908-13.93-14.475-25.073a16.718 16.718 0 0 1 14.475-8.357h121.234c9.239-.011 16.729 7.478 16.717 16.715z' fill='%23202020'/%3E%3C/svg%3E");
}

.pio-action .pio-close {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M249.999 198.668L352.665 96c14.667-14.666 36.668-14.666 51.335 0 14.666 14.667 14.666 36.668 0 51.334L301.333 250 404 352.668c14.666 14.667 14.666 36.666 0 51.332-14.667 14.667-36.667 14.667-51.334 0L249.999 301.334 147.333 404c-14.668 14.667-36.666 14.667-51.334 0-14.666-14.666-14.666-36.665 0-51.332L198.666 250 95.999 147.334c-14.666-14.666-14.666-36.667 0-51.334 14.668-14.666 36.665-14.666 51.333 0l102.667 102.668z' fill='%23873a18'/%3E%3C/svg%3E");
}

.pio-action .pio-skin {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 249.999C5 385.312 114.688 495 250.001 495 385.312 495 495 385.312 495 249.999 495 114.688 385.312 5 250.001 5 114.688 5 5 114.688 5 249.999z' fill='%23FEC43C'/%3E%3Cpath d='M490.1 200.509C467.071 88.789 368.58 5 250.001 5 114.76 5 5 114.76 5 249.999 5 309.29 26.07 363.19 60.86 405.33c41.65 23.03 89.18 35.77 140.14 35.77 144.06 0 263.621-103.88 289.1-240.591z' fill='%23FFD73A'/%3E%3Cpath d='M116.712 225.3c0 34.539 37.39 56.125 67.301 38.856a44.868 44.868 0 0 0 22.433-38.856c0-24.779-20.089-44.866-44.87-44.866-24.778 0-44.864 20.087-44.864 44.866zm219.346 0c0 24.779 20.087 44.866 44.867 44.866 24.778 0 44.864-20.091 44.864-44.866 0-24.779-20.09-44.864-44.866-44.864-24.778 0-44.865 20.085-44.865 44.864z' fill='%23873A18'/%3E%3Cpath d='M271.251 334.973H201.46c-8.474 0-14.956-6.482-14.956-14.956 0-8.477 6.482-14.957 14.956-14.957h69.791c8.476 0 14.957 6.483 14.957 14.957s-6.484 14.956-14.957 14.956zM158.59 210.343H79.818c-6.979 0-12.962-6.482-12.962-14.957 0-8.473 5.982-14.95 12.966-14.95h78.763c6.975 0 12.962 6.478 12.962 14.95 0 8.478-5.987 14.957-12.966 14.957zm217.348 0h-74.775c-8.477 0-14.956-6.482-14.956-14.957 0-8.473 6.484-14.95 14.956-14.95h74.774c8.478 0 14.956 6.478 14.956 14.95 0 8.478-6.482 14.957-14.956 14.957z' fill='%23873A18'/%3E%3Cpath d='M54 161.799c-26.95 0-49-22.05-49-48.999C5 68.699 54 5 54 5s48.999 63.699 48.999 107.8c0 26.949-22.049 48.999-48.999 48.999z' fill='%2361A3E0'/%3E%3C/svg%3E");
}

.pio-action .pio-info {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'%3E%3Crect transform='rotate(45.001 238.211 363.575)' x='29.285' y='22.411' width='273.903' height='505.038' rx='70' ry='70' fill='%23dcdcdc'/%3E%3Cpath d='M218.543 249.999l-47.186 47.186c-8.987 8.988-8.987 22.47 0 31.457 8.988 8.988 22.47 8.988 31.457 0L250 281.456l15.728 15.729c17.976 17.976 17.976 46.063 0 64.038l-64.037 64.038c-17.976 17.975-46.063 17.975-64.038 0l-64.038-64.038c-17.975-17.975-17.975-46.062 0-64.038l64.038-64.037c17.975-17.976 46.062-17.976 64.038 0l16.852 16.851z' fill='%23fff'/%3E%3Cpath d='M281.457 249.999l47.186-47.186c8.988-8.987 8.988-22.469 0-31.457-8.987-8.987-22.469-8.987-31.457 0L250 218.542l-15.729-15.729c-17.975-17.975-17.975-46.062 0-64.037l64.038-64.038c17.975-17.975 46.062-17.975 64.038 0l64.037 64.038c17.977 17.975 17.977 46.062 0 64.037l-64.037 64.038c-17.976 17.976-46.063 17.976-64.038 0l-16.852-16.852z' fill='%2361a3e0'/%3E%3C/svg%3E");
}

.pio-action .pio-night {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M186.744 40.493a15.34 15.34 0 0 1 9.956-.097 15.743 15.743 0 0 1 10.643 17.018l-.639 2.794-2.549 8.484a230.05 230.05 0 0 0 21.824 175.818c41.315 73.085 119.925 117.041 203.82 113.977l8.093-.491h2.649c11.217 1.139 18.309 12.601 14.32 23.148l-1.374 2.795-2.256 3.727-6.472 8.533a225.604 225.604 0 0 1-62.629 54.194c-107.795 63.019-245.655 25.599-307.89-83.57-62.235-109.12-25.257-248.695 82.49-311.715a219.638 219.638 0 0 1 17.755-9.319l9.022-4.07 3.286-1.226zm176.702 52.576a4.402 4.402 0 0 1 2.108 2.156l11.181 23.982c1.08 2.305 2.944 4.218 5.297 5.296l24.031 11.182c3.06 1.482 3.366 5.717.554 7.626-.176.12-.361.227-.554.32l-24.031 11.181a10.994 10.994 0 0 0-5.297 5.296l-11.181 24.031c-1.482 3.058-5.717 3.366-7.625.554a4.11 4.11 0 0 1-.32-.554l-11.23-24.031a10.998 10.998 0 0 0-5.297-5.296l-23.981-11.181c-3.06-1.482-3.367-5.717-.555-7.626.176-.12.362-.227.555-.32l24.029-11.182a10.993 10.993 0 0 0 5.249-5.296l11.23-24.031a4.412 4.412 0 0 1 5.837-2.107zm-62.825-73.812c.491.246.883.589 1.08 1.08l5.59 11.967a5.346 5.346 0 0 0 2.649 2.647l12.015 5.592c1.548.699 1.757 2.812.377 3.804-.12.084-.244.157-.377.218l-12.015 5.589a5.496 5.496 0 0 0-2.649 2.649l-5.59 11.966c-.778 1.512-2.9 1.611-3.819.182a2.694 2.694 0 0 1-.105-.182l-5.64-11.966a5.491 5.491 0 0 0-2.649-2.649l-11.966-5.64c-1.548-.699-1.757-2.814-.377-3.803.119-.085.244-.158.377-.218l11.966-5.589a5.498 5.498 0 0 0 2.649-2.65l5.591-11.965a2.205 2.205 0 0 1 2.942-1.08z' fill='%23FFCB3C'/%3E%3C/svg%3E");
}

.pio-container .pio-dialog {
    top: -2em;
    opacity: 0;
    z-index: -1;
    font-size: .8em;
    min-width: 12em;
    background: #fff;
    padding: .75em 1em;
    border-radius: 1em;
    visibility: hidden;
    position: absolute;
    word-break: break-all;
    border: 1px solid #eee;
    transition: opacity .3s, visibility .3s;
}

.pio-container .pio-dialog.active {
    opacity: 1;
    visibility: visible;
}

.pio-container.left .pio-dialog {
    left: 1em
}

.pio-container.right .pio-dialog {
    right: 1em
}

#pio {
    vertical-align: middle
}

@media screen and (max-width: 768px) {
    #pio {
        width: 8em
    }

    .pio-container {
        pointer-events: none
    }
}